
<div class="slide-in-right justify-content-center">
    <form [formGroup]="form" (ngSubmit)="this.formCompleto(1)">
        <div class="row d-flex">
            <div class="row">
                <div class="col">
                    <h2 style="font-size: 40px; color: var(--colorBase)">Completá el formulario:</h2>
                </div>
            </div>
            <div class="row">
                <div class="col">
                    <h3 style="font-size: 20px;" class="mt-4 mb-4">Datos del vehículo:</h3>
                </div>
            </div>
            <div class="row mb-4">
                <div class="col-12 col-sm-12 col-md-4">
                        <label for="marca">Marca</label>
                        <div class="form-group">
                            <mat-form-field appearance="outline">
                                <input type="text" matInput [formControl]="marca" [matAutocomplete]="auto">
                                <mat-autocomplete #auto="matAutocomplete" [displayWith]="displayMarca">
                                  <mat-option *ngFor="let option of marcasFiltradas | async" [value]="option">
                                    {{option.name}}
                                  </mat-option>
                                </mat-autocomplete>
                                <div *ngIf="f['marca'].touched && f['marca'].invalid">
                                    <small *ngIf="f['marca'].errors && f['marca'].errors['required']" class="text-danger">La marca es requerida</small>
                                </div> 
                            </mat-form-field>
                        </div>
                    </div>

                    <div class="col-12 col-sm-4 col-md-2">
                        <label for="anio">Año</label>
                        <div class="form-group">
                            <mat-form-field appearance="outline">
                                <input type="number" matInput [formControl]="anio" >
                                <div *ngIf="f['anio'].touched && f['anio'].invalid">
                                    <small *ngIf="f['anio'].errors && f['anio'].errors['required']" class="text-danger">El año es requerido</small>
                                    <small class="text-danger" *ngIf="f['anio']?.errors['min'] || f['anio']?.errors['max'] && f['anio'].touched">El año debe estar entre 1900 y 2024</small>
                                </div>
                            </mat-form-field>
                        </div>
                    </div>

                    <div class="col-sm-12 col-md-6">
                        <label for="modelo">Modelo</label>
                        <div class="form-group">
                            <mat-form-field appearance="outline">
                                <input type="text" matInput [formControl]="modelo" [matAutocomplete]="autoModelo">
                                <mat-autocomplete #autoModelo="matAutocomplete" [displayWith]="displayModelo">
                                  <mat-option *ngFor="let option of modelosFiltrados | async" [value]="option">
                                    {{option.description}}
                                  </mat-option>
                                </mat-autocomplete>
                                <div *ngIf="f['modelo'].touched && f['modelo'].invalid">
                                    <small *ngIf="f['modelo'].errors && f['modelo'].errors['required']" class="text-danger">El modelo es requerido</small>
                                </div>
                            </mat-form-field>
                        </div>
                    </div>

                    <div  class="col mt-4">
                        <div style="font-size: 100%;">
                            <fa-icon [icon]="faCircleExclamation"></fa-icon>
                            Los seguros a cotizar son válidos para vehículos de uso particular no comercial radicados en Quilmes, Buenos Aires
                        </div>
                    </div>
            </div>
            
            <div class="row justify-content-end mt-5">
                <div class="d-flex justify-content-end">
                    <button type="submit" class="btn btn-lg btn-outline-primary" style="height: 100%; width: 100%" [disabled]="!this.formValido">
                    Cotizar 
                    </button>
                </div>
            </div>
    </div>

</form>
</div>

<div class="preloader-wrapper" *ngIf="this.mostrarSpinner">
    <div class="spinner-border" style="width: 8.5rem; height: 8.5rem; color: var(--colorBase); font-size: large;">

    </div>
</div>
