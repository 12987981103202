
<header>
  <nav class="navbar navbar-light justify-content-end" style="background-color: var(--colorBase);">
    <span class="navbar-text" style="margin-right: 20px;">
      <fa-icon [icon]="faSquarePhone" style="color: white; margin-right: 5px;"></fa-icon>
      <b style="color:white; font-size: 15px">Solicite Asesor: 11 4026 4959</b>
    </span>
  </nav>
</header>

  <div class="container py-4">

    <div class="p-3 mb-4  bg-light rounded-3 card">
      <div class="container-fluid py-5">
          <app-formulario *ngIf="this.step == 0" (cambioStep)="eventoStep($event)"></app-formulario>
          <app-seleccion *ngIf="this.step == 1" (cambioStep)="eventoStep($event)"></app-seleccion>
          <app-form-contratar *ngIf="this.step == 2" (cambioStep)="eventoStep($event)"></app-form-contratar>
          <app-confimacion-email *ngIf="this.step == 3" (cambioStep)="eventoStep($event)"></app-confimacion-email>
      </div>
    </div>

  


  </div>




