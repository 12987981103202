<div class="slide-in-right">
    <div>
        <div>
            <h2 class="display-4 fw-normal" style="font-size: 50px; color: var(--colorBase)">Contratá</h2>
          </div>
          <div class="d-flex justify-content-start mt-3">
            <ul class="text-start">
              <li class="dato"> <span class="titulo">Vehículo:  </span> {{this.dataService.datosAuto.marca.name}} {{this.dataService.datosAuto.modelo.description}} {{this.dataService.datosAuto.anio}}</li>
              <li class="dato"> <span class="titulo">Plan:  </span> {{this.dataService.datosAuto.plan.descripcionCobertura}}</li>
              <li class="dato"> <span class="titulo">Suma asegurada:  </span> {{this.dataService.datosCotizacion[0].sumaAsegurada | currency}}</li>
            </ul>
        </div>
    </div>
    <div class="card p-4 justify-content-center">
      <form [formGroup]="form" (submit)="this.formCompleto()">
        <div class="row d-flex">
            <div class="row">
                <div class="col">
                    <h2 style="font-size: 40px; color: var(--colorBase)">Datos de contacto</h2>
                </div>
            </div>
            <div class="row mb-4">
                    <div class="col-sm-12 col-md-6 col-lg-4">
                    <label for="dni">N° de DNI</label>
                    <input type="number" class="form-control" formControlName="dni" id="dni" placeholder="" value="" required>
                        <div *ngIf="f['dni'].touched && f['dni'].invalid">
                            <small *ngIf="f['dni'].errors && f['dni'].errors['required']" class="text-danger">El dni es requerido</small>
                            <small class="text-danger" *ngIf="f['dni']?.errors['min'] || f['dni']?.errors['max'] && f['dni'].touched">El número de dni debe ser valido</small>
                        </div>
                    </div>

                    <div class="col-sm-12 col-md-6 col-lg-4" style="align-items: center;">
                        <label for="telefono">Teléfono celular</label>
                        <input type="number" class="form-control" formControlName="telefono" id="telefono" placeholder="" required>
                        <div *ngIf="f['telefono'].touched && f['telefono'].invalid">
                            <small *ngIf="f['telefono'].errors && f['telefono'].errors['required']" class="text-danger">El número de teléfono es requerido</small>
                        </div>
                    </div>

                    
                    <div class="col-12 col-sm-6 col-md-6 col-lg-4">
                        <label for="email">Email</label>
                        <input type="text" class="form-control" formControlName="email" id="email" placeholder="" value="" required>
                            <div *ngIf="f['email'].touched && f['email'].invalid">
                                <small *ngIf="f['email'].errors && f['email'].errors['required']" class="text-danger">El email es requerido</small>
                                <small *ngIf="f['email'].errors['email']" class="text-danger">Ingrese un email valido</small>
                            </div>
                        </div>

            </div>
    
            <div class="row justify-content-end mt-5">
                <div class="d-flex justify-content-end">
                    <button type="submit" class="btn btn-lg btn-outline-primary" style="height: 100%; width: 100%" [disabled]="form.invalid">
                    Finalizar 
                    </button>
                </div>
            </div>
        </div>
    
      </form>
    </div>

</div>

<div class="preloader-wrapper" *ngIf="this.mostrarSpinner">
    <div class="spinner-border" style="width: 8.5rem; height: 8.5rem; color: var(--colorBase); font-size: large;">

    </div>
</div>
