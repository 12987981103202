<div class="slide-in-right">
  <div class="pricing-header p-3 pb-md-4 mx-auto text-center">
    <div>
      <h2 class="display-4 fw-normal" style="font-size: 50px; color: var(--colorBase)">Elegí tu plan:</h2>
    </div>
    <div class="d-flex justify-content-start">
      <ul class="text-start">
        <li class="dato"> <span class="titulo">Vehículo:  </span> {{this.dataService.datosAuto.marca.name}} {{this.dataService.datosAuto.modelo.description}} {{this.dataService.datosAuto.anio}}</li>
        <li class="dato"> <span class="titulo">Suma asegurada:  </span> {{this.dataService.datosCotizacion[0].sumaAsegurada | currency}}</li>
      </ul>
    </div>
  </div>

    <div class="row row-cols-1 row-cols-md-3 mb-3 text-center">
      <div class="col" *ngFor="let item of this.coberturas">
        <div class="card mb-4 rounded-3 shadow-sm" [ngClass]="{'cardSeleccionado': planSeleccionado?.descripcionCobertura === this.item.descripcionCobertura, 'card-outline-primary': planSeleccionado?.descripcionCobertura !== this.item.descripcionCobertura}">
          <div class="card-header py-3">
            <h4 class="my-0 fw-normal">{{this.item.descripcionCobertura}}</h4>
          </div>
          <div class="card-body">
            <small class="text-muted fw-light" style="font-size: 25px">Por mes</small>
            <h1 class="card-title pricing-card-title" style="font-size: 50px;">{{this.item.premio | currency}}</h1>
            <ul class="list-unstyled mt-3 mb-4">
              <li>10 users included</li>
              <li>2 GB of storage</li>
              <li>Email support</li>
              <li>Help center access</li>
            </ul>
            <button type="button" class="w-100 btn btn-lg btn-outline-primary" (click)="seleccionPlan(this.item)" [ngClass]="{'botonSeleccionado2': planSeleccionado?.descripcionCobertura === this.item.descripcionCobertura, 'btn-outline-primary': planSeleccionado?.descripcionCobertura !== this.item.descripcionCobertura}">Seleccionar</button>
          </div>
        </div>
      </div>
    </div>

    <div class="d-flex justify-content-end">
      <button type="submit" class="btn btn-lg btn-outline-primary" style="height: 100%; width: 100%" [disabled]="this.planSeleccionado == null" (click)="this.selecCompleto(2)">
      Seleccionar 
      </button>
    </div>
</div>





